/* utilities.css */
.visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.divider {
    display: block;
    border: none;
    border-top: 1px solid var(--color-brand-aluminium);
    margin: 0;
    width: 100%;
    max-width: none;
    transform: none;
}

.divider[data-color='steel'] {
    border-top: 1px solid var(--color-brand-steel);
}

.divider[data-color='white'] {
    border-top: 1px solid var(--color-brand-white);
}

.divider[data-color='black'] {
    border-top: 1px solid var(--color-brand-black);
}

@media (max-width: 767px) {
    .hidden-on-mobile {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .hidden-on-desktop {
        display: none !important;
    }
}
